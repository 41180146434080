import { Component } from '@angular/core';

import * as moment from 'moment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor() {
    // Update the 'es' locale globally without dots in the short month names
    moment.updateLocale('es', {
      monthsShort: 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_')
    });
  }

  title = 'esenssapp';
}
